
import { Vue, Component } from 'vue-property-decorator';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import IconArrow from '@/components/icons/IconArrow.vue';

@Component({
  components: {
    BaseButton,
    IconArrow,
  },
})
export default class Blog extends Vue {
  articles = [
    {
      title: 'Merry Christmas and Happy New Year!',
      category: 'Business',
      date: 'December 24, 2022',
      link: 'https://blog.creditpush.com/merry-christmas-and-happy-new-year/',
    },
    {
      title: 'The Best Way to Track Your Business Expenses for Free',
      category: 'Business',
      date: 'December 20, 2022',
      link: 'https://blog.creditpush.com/the-best-way-to-track-your-business-expenses-for-free/',
    },
    {
      title: 'Why You Need to Connect a Bank Account When Calculating Carbon Footprint',
      category: 'Business',
      date: 'December 5, 2022',
      link: 'https://blog.creditpush.com/calculating-carbon-footprint/',
    },
    {
      title: 'How To Value Your Business: The Business Valuation Formulas',
      category: 'Business',
      date: 'November 1, 2022',
      link: 'https://blog.creditpush.com/how-to-value-your-business-the-best-business-valuation-formulas/',
    },
  ];

  openLink(link: string): void {
    window.open(link, '_self');
  }
}
